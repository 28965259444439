import './PaymentsSafety.css'

const PaymentsSafety = () => {
    return (
        <div class="payments-safety">
            <h1>Политика информационной безопасности</h1>
            <p>Оплатить заказ можно банковскими картами Visa, Master Card, «Мир» или через платежные системы Apple Pay, Qiwi, ЮMoney. Чтобы оплатить покупку, вы будете перенаправлены на сервер платежной системы Unitpay, на котором нужно ввести необходимые данные. При оплате банковской картой безопасность платежей гарантирует процессинговый центр Unitpay.</p>
            <p>Платежная система Unitpay обладает подтвержденным сертификатом соответствия требованиям стандарта PCI DSS в части хранения, обработки и передачи данных держателей карт. Стандарт безопасности банковских карт PCI DSS поддерживается международными платежными системами, включая MasterCard и Visa, Inc. Система Unitpay также является участником программы непрерывного соответствия Compliance Control PCI DSS Compliance Process (P.D.C.P.). Ваши конфиденциальные данные, необходимые для оплаты (реквизиты карты, регистрационные данные и др.), не поступают в интернет-магазин — их обработка производится на стороне процессингового центра Unitpay и полностью защищена.</p>
        </div>
    );
};

export default PaymentsSafety;