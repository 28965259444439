import './Homepage.css'
import { HiOutlineShieldCheck, HiOutlineIdentification } from "react-icons/hi";
import { LuGem, LuBoxes } from "react-icons/lu";
import Faq from "../../components/Faq/Faq"
import { Link } from 'react-router-dom';

const Homepage = () => {

    return (
        <div>
            <div className="welcome section">
                <h1>Накрутка в социальных сетях</h1>
                <p>Более 200 000 клиентов. Работаем с 2018 года.</p>
                <div className="actions">
                    <Link to='/services/list/' className="btn">
                        <LuBoxes size={20} />
                        Посмотреть услуги
                    </Link>
                </div>
            </div>

            <div className="statistic section">
                <div className="row">
                    <div className="col-md-4">
                        <div className='badge'>
                            <HiOutlineShieldCheck size={25} />
                        </div>
                        <h2>Защита от бана</h2>
                        <p className='color-gray'>Умные алгоритмы для защиты от блокировки вашего аккаунта.</p>
                    </div>
                    <div className="col-md-4">
                        <div className='badge'>
                            <HiOutlineIdentification size={25} />
                        </div>
                        <h2>Проверенные поставщики</h2>
                        <p className='color-gray'>Тщательно проверяем поставщиков. Накручиваем только качественных ботов.</p>
                    </div>
                    <div className="col-md-4">
                        <div className='badge'>
                            <LuGem size={25} />
                        </div>
                        <h2>Эксклюзивные услуги</h2>
                        <p className='color-gray'>Оказываем уникальные услуги на рынке накрутки.</p>
                    </div>
                </div>
            </div>

            <Faq/>
        </div>
    );
};

export default Homepage;