import React, { useState } from 'react';
import "./Faq.css"


const Faq = () => {
    const [faqs, setFaqs] = useState([
        { question: 'Какую ссылку нужно указывать?', answer: 'Для лайков, просмотров, сохранений и комментариев должна быть указана ссылка на публикацию. Для просмотров историй и для подписчиков - ссылка на профиль.' },
        { question: 'Можно ли запустить накрутку на закрытый аккаунт?', answer: 'На закрытый аккаунт нельзя запустить накрутку. Перед оформлением заказа убедитесь, что аккаунт открыт (не приватный) иначе заказ будет отменён.' },
        { question: 'Заказ принят, но накрутка не происходит', answer: 'Каждый заказ стартует в пределах 20 минут. В некоторых случаях при большом спросе и повышении нагрузки, время ожидания может быть увеличено. Примерное время запуска указано в описании к услуге' },
        { question: 'Проблемы с заказом, что делать?', answer: 'Если вы наблюдаете как заказ резко остановился, аннулировался, как статус не соответствует фактическому исполнению заказа, или другие проблемы, обращайтесь в поддержку.' },
    ]);

    const toggleFAQ = (index) => {
        const updatedFaqs = [...faqs];
        updatedFaqs[index].isOpen = !updatedFaqs[index].isOpen;
        setFaqs(updatedFaqs);
    };

    return (
        <div className="faq section">
            <h2>Часто задаваемые вопросы</h2>
            {faqs.map((faq, index) => (
                <div key={index} onClick={() => toggleFAQ(index)} className="faq-item">
                    <div>{faq.question}</div>
                    {faq.isOpen && <p>{faq.answer}</p>}
                </div>
            ))}
        </div>
    );
};

export default Faq;