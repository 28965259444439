import React from 'react';
import ServiceSchema from '../../types/service'
import "./ServiceDescription.css";

const ServiceDescription: React.FC<{ service: ServiceSchema }> = ({ service }) => {
  return (
    <div>
      {service.tags.length > 1 && (
        <ul className='service-tags'>
          {service.tags.map((tag, index) => (
            <li key={index} className='tag'>{tag.name}</li>
          ))}
        </ul>
      )}
      <div className="">
        <div dangerouslySetInnerHTML={{ __html: service.description }} />
        <div className="list">
          <div className='list-item'>
          <span className='color-gray'>
            {service.is_price_per_one ? 'Стоимость (1 шт.):' : 'Стоимость (100 шт.):'}
          </span> 
          <span>{service.price}₽</span></div>
          <div className='list-item'><span className='color-gray'>Минимум:</span> {service.min_value}</div>
          <div className='list-item'><span className='color-gray'>Максимум:</span> {service.max_value}</div>
        </div>
        {service.speed && <div><span className='color-gray'>⚡️ Скорость:</span> {service.speed}</div>}
        {service.avg_start_time && <div><span className='color-gray'>🕐 Очередь на запуск:</span> {service.avg_start_time}</div>}
        
      </div>
    </div>
  );
};

export default ServiceDescription;