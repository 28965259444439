import React from 'react';
import "./Footer.css"
import { Link } from 'react-router-dom';
import { LiaTelegramPlane } from "react-icons/lia";

const Footer = () => {
    return (
        <div className="footer">
            <div className="content-width">
                <div className="row">
                    <div className="col-md-4">
                        <ul>
                            <li>
                                <Link to='services/list/' className="a--gray">
                                Накрутка Instagram
                                </Link>
                            </li>
                            <li>
                                <Link to='services/list/' className="a--gray">
                                Накрутка Telegram
                                </Link>
                            </li>
                            <li>
                                <Link to='services/list/' className="a--gray">
                                Накрутка Вконтакте
                                </Link>
                            </li>
                            <li>
                                <Link to='services/list/' className="a--gray">
                                Накрутка TikTok
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="col-md-4">
                        <ul>
                            {/* <li><a href="" className='a--gray'>Анонимный просмотр историй</a></li> */}
                            <li>
                                <Link to='rules/' className="a--gray">
                                    Пользовательское соглашение
                                </Link>
                            </li>
                            <li>
                                <Link to='privacy/' className="a--gray">
                                    Политика конфиденциальности
                                </Link>
                            </li>
                            <li>
                                <Link to='payments-safety/' className="a--gray">
                                    Безопасность платежей
                                </Link>
                            </li>
                            <li className='org-info'>
                                <p>ИП Молчанов Илья Алексеевич</p>
                                <p>ИНН: 695204781651</p>
                                <p>ОГРН: 320695200036781</p>
                            </li>
                        </ul>
                    </div>
                    <div className="col-md-4">
                        <ul>
                            <li><a href="https://t.me/instakrutka_support" target='_blank' className='btn btn--small btn--dark-gray'><LiaTelegramPlane size={20} /> Чат поддержки</a></li>
                            <li><a href="mailto:hello@gramotool.ru" className='a--black'>hello@gramotool.ru</a></li>
                            <li className='payment-icons'>
                                <img src="../../mir.svg" height={12} alt="мир логотип" />
                                <img src="../../visa.svg" height={11} alt="visa логотип" />
                                <img src="../../mastercard.svg" height={14} alt="mastercard логотип" />
                                <img src="../../qiwi.svg" height={18} alt="qiwi логотип" />
                                <img src="../../sbp.svg" height={18} alt="сбп логотип" />
                            </li>
                        </ul>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default Footer;