import './OrderCreate.css'
import React, { useEffect, useState } from 'react';
import { getSocialList, getCategoriesList, getServicesList } from "../../api/api";
import ServiceDescription from "../../components/ServiceDescription/ServiceDescription.tsx"
import { HiOutlineReceiptTax } from "react-icons/hi";
import LoginOffer from "../../components/LoginOffer/LoginOffer"
import OrderTotal from "../../components/OrderTotal/OrderTotal"

const OrderCreate = () => {
    const [socialList, setSocialList] = useState([]);
    const [selectedSocial, setSelectedSocial] = useState([]);

    const [categoryList, setCategoryList] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState([]);

    const [serviceList, setServiceList] = useState([]);
    const [selectedService, setSelectedService] = useState({});

    const onServiceSelect = (service_id) => {
        const selectedService = serviceList.find(item => item.id === parseInt(service_id));
        setSelectedService(selectedService);
    }

    const getSocials = async () => {
        try {
            const response = await getSocialList();
            setSocialList(response);
        } catch (error) {
            console.error('Ошибка при получении соц.сетей', error);
        }
    }
    const getCategories = async (social) => {
        try {
            const response = await getCategoriesList(social);
            setCategoryList(response);
        } catch (error) {
            console.error('Ошибка при получении категорий', error);
        }
    }
    const getServices = async (social, category) => {
        try {
            const response = await getServicesList(social, category);
            setServiceList(response);
        } catch (error) {
            console.error('Ошибка при получении услуг', error);
        }
    }

    useEffect(() => {
        getSocials();
    }, []);

    useEffect(() => {
        if (Object.keys(socialList).length !== 0) {
            setSelectedSocial(Object.keys(socialList)[0]);
        }
    }, [socialList]);

    useEffect(() => {
        getCategories(selectedSocial);
    }, [selectedSocial]);

    useEffect(() => {
        getServices(selectedSocial, selectedCategory);
    }, [selectedCategory]);

    return (
        <div className='services'>
            <h1>Услуги</h1>
            <div className="row">
                <div className="col-md-6">
                    <label htmlFor="social">Социальная сеть</label>
                    <select name="social" className='input' onChange={(e) => setSelectedSocial(e.target.value)}>
                        {Object.entries(socialList).map((elem, index) => (
                            <option value={elem[0]}>{elem[1]}</option>
                        ))}
                    </select>

                    <label htmlFor="category">Категория</label>
                    <select name="category" className='input' onChange={(e) => setSelectedCategory(e.target.value)}>
                        {Object.entries(categoryList).map((elem, index) => (
                            <option value={elem[0]}>{elem[1]}</option>
                        ))}
                    </select>

                    <label htmlFor="service">Услуга</label>
                    <select name="service" className='input' onChange={(e) => onServiceSelect(e.target.value)}>
                        <option value="">---</option>
                        {serviceList.map((elem, index) => (
                            <option value={elem.id}>{elem.name} - {elem.price}₽</option>
                        ))}
                    </select>

                    {/* <label htmlFor="link">Ссылка</label>
                    <input name="link" className='input'></input> */}

                    <label htmlFor="count">Количество</label>
                    <input name="count" className='input'></input>
                    <div className='offer card'>
                        <HiOutlineReceiptTax size={30} className='primary-color' />
                        <div>Закажите 180 просмотров чтобы получить скидку 10%</div>
                    </div>

                    {Object.keys(selectedService).length !== 0 && (
                        <ServiceDescription service={selectedService} />
                    )}
                </div>
                <div className="col-md-6">
                    <OrderTotal />
                    <LoginOffer />
                </div>
            </div>
        </div>
    );
};

export default OrderCreate;