import React, {useState, useEffect } from 'react';
import './ServicesTable.css'
import { getServicesList } from "../../api/api";

const ServicesTable = () => {
    const [categoryFilter, setCategoryFilter] = useState('');
    const [socialNetworkFilter, setSocialNetworkFilter] = useState('');
    const [sortByCost, setSortByCost] = useState(false);
    const [serviceList, setServiceList] = useState([]);

    // Функция для фильтрации данных услуг
    const filteredServices = serviceList.filter((service) => {
        return (
            // (categoryFilter === '' || service.category === categoryFilter) &&
            (socialNetworkFilter === '' || service.service_social === socialNetworkFilter)
        );
    });
    if (sortByCost) {
        filteredServices.sort((a, b) => a.price - b.price);
    }

    // Функция для получения списка услуг
    const getServices = async () => {
        try {
            const response = await getServicesList();
            setServiceList(response);
        } catch (error) {
            console.error('Ошибка при получении услуг', error);
        }
    }
    
    useEffect(() => {
        getServices();
    }, []);

    return (
        <div className="services-table">
            <h1>Наши услуги</h1>
            <div className="filters">

                <select onChange={(e) => setSocialNetworkFilter(e.target.value)}>
                    <option value="">Все соц. сети</option>
                    <option value="Instagram">Instagram</option>
                    <option value="Telegram">Telegram</option>
                    <option value="Вконтакте">Вконтакте</option>
                    <option value="TikTok">TikTok</option>
                </select>
                <button className="sort-button" onClick={() => setSortByCost(!sortByCost)}>
                    {sortByCost ? 'Сначала дешевые' : 'Сначала дорогие'}
                </button>
            </div>
            {/* Таблица услуг */}
            <div className='table-container'>
            <table>
                <thead>
                    <tr>
                        <th>Услуга</th>
                        <th>Соц. сеть</th>
                        <th>Стоимость (1000шт.)</th>
                        <th>Минимум</th>
                        <th>Максимум</th>
                        <th>Описание</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredServices.map((service) => (
                        <tr key={service.id}>
                            <td>{service.name}</td>
                            <td>{service.service_social}</td>
                            <td>{service.price}</td>
                            <td>{service.min_value}</td>
                            <td>{service.max_value}</td>
                            <td dangerouslySetInnerHTML={{ __html: service.description }}/>
                        </tr>
                    ))}
                </tbody>
            </table>
            </div>
        </div>
    );
};

export default ServicesTable;