import React from 'react';
import "./InputError.css";

function ErrorList({ name, errors }) {
    const errorList = errors[name] || [];

    if (errorList.length === 0) {
        return null;
    }

    return (
        <div>
            <ul className='error-list'>
                {errorList.map((error, index) => (
                    <li key={index}>{error}</li>
                    ))}
            </ul>
        </div>
        );
}

export default ErrorList;