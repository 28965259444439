import React from 'react';
import "./Header.css"
import { useSelector } from "react-redux";
import { HiOutlinePlusSm } from "react-icons/hi";
import { Link } from 'react-router-dom';

const telegramAuthUrl = "https://t.me/framemork_test_bot?start=auth";

const Header = () => {
    
    return (
        <div className='header content-width'>
            <Link to='/' className="logo">
                <div className='logo-square'/>
                Gramotool
            </Link>
            <div className="nav">
                <Link to='/services/list/' className='a--black'>
                    Наши услуги
                </Link>
            </div>
            {/*<div className="actions">
                <Link to='/order/create/' className="btn btn--tiny btn--black">
                    <HiOutlinePlusSm size={20}/>
                    Создать заказ
                </Link>
                 <a href={telegramAuthUrl} className='btn btn--small '>
                    <LiaTelegramPlane size={20} />
                    Войти c Telegram
                </a> 
                <Link to='/balance/' className="btn btn--tiny">
                    12.32₽
                </Link>
            </div>*/}
        </div>
    );
};

export default Header;