const initialState = {
    user_uuid: null,
    balance: null,
};

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_USER_UUID':
            return {
                ...state,
                user_uuid: action.payload.user_uuid,
            };
        case 'SET_USER_PROFILE':
            return {
                ...state,
                user_uuid: action.payload.data.unique_id,
                balance: action.payload.data.money,
            };
        default:
            return state;
    }
};

export default userReducer;