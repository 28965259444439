import './Balance.css'
import React, { useEffect, useState } from 'react';

const Balance = () => {
    const [selectedMethod, setSelectedMethod] = useState(null);

    const handleMethodChange = (e) => {
        setSelectedMethod(e.target.value);
    };

    return (
        <div className='balance'>
            <h1>Баланс</h1>
            <div className="row">
                <div className="col-md-6">
                    <div className="card card--light-gray">
                        <div className='card-section'>
                            <h2>Пополнить баланс</h2>
                            <label htmlFor="amount">
                                Сумма пополнения
                                <input type="number" min={50} max={50000} name='amount' id="amount" className='input' />
                                <span className='input-help'>Минимум: 50. Максимум: 50 000</span>
                            </label>
                        </div>

                        <div className='card-section'>
                            <h3>Выберите способ оплаты:</h3>
                            <div>
                                <label className='radio-button'>
                                    <input
                                        type="radio"
                                        name="paymentMethod"
                                        value="card"
                                        checked={selectedMethod === 'card'}
                                        onChange={handleMethodChange}
                                    />
                                    Банковской картой(РФ)
                                </label>
                            </div>
                            <div>
                                <label className='radio-button'>
                                    <input
                                        type="radio"
                                        name="paymentMethod"
                                        value="qiwi"
                                        checked={selectedMethod === 'qiwi'}
                                        onChange={handleMethodChange}
                                    />
                                    Qiwi
                                </label>
                            </div>
                            <div>
                                <label className='radio-button'>
                                    <input
                                        type="radio"
                                        name="paymentMethod"
                                        value="sberbank"
                                        checked={selectedMethod === 'sberbank'}
                                        onChange={handleMethodChange}
                                    />
                                    Сбербанк онлайн
                                </label>
                            </div>
                            <div>
                                <label className='radio-button'>
                                    <input
                                        type="radio"
                                        name="paymentMethod"
                                        value="yoomoney"
                                        checked={selectedMethod === 'yoomoney'}
                                        onChange={handleMethodChange}
                                    />
                                    ЮMoney
                                </label>
                            </div>
                        </div>
                        <div className="btn card-section">
                            Пополнить баланс
                        </div>
                    </div>
                </div>

                <div className="col-md-6">
                    <h2>История пополнений</h2>
                    <ul className='balance-history'>
                        <li className='item'>
                            <div>
                                <div className='history-item-amount'>
                                    +15.00₽
                                </div>
                                <div className="date">
                                    13 мая 2022
                                </div>
                            </div>

                            <div className="color-gray">
                                Qiwi
                            </div>
                        </li>
                        <li className='item'>
                            <div>
                                <div className='history-item-amount'>
                                    +15.00₽
                                </div>
                                <div className="date">
                                    13 мая 2022
                                </div>
                            </div>

                            <div className="color-gray">
                                Qiwi
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Balance;