import axios from 'axios';

const baseURL = process.env.REACT_APP_API_URL;

const api = axios.create({
    baseURL,
});

// Получить информацию по заказу
export const getOrder = async (params) => {
    try {
        const response = await api.get('/orders/get/', {params: params});
        return response.data;
    } catch (error) {
        throw error;
    }
};

// Обновить заказ
export const updateOrder = async (order_uuid, data) => {
    const endpoint_url = `/orders/${order_uuid}/update/`
    try {
        const response = await api.patch(endpoint_url, data);
        return response.data;
    } catch (error) {
        throw error;
    }
};

// Подтвердить заказ
export const submitOrder = async (order_uuid) => {
    const endpoint_url = `/orders/${order_uuid}/submit/`
    try {
        const response = await api.post(endpoint_url);
        return response.data;
    } catch (error) {
        throw error;
    }
};

// Получить профиль пользователя
export const getUser = async (user_unique_id) => {
    const endpoint_url = `/user/${user_unique_id}/`
    try {
        const response = await api.get(endpoint_url);
        return response.data;
    } catch (error) {
        throw error;
    }
};

// Создать платёж для заказа
export const createInvoice = async (order_unique_id) => {
    const endpoint_url = `/payment/bot/create-invoice/`
    const data = {
        order_unique_id: order_unique_id,
    }
    try {
        const response = await api.post(endpoint_url, data);
        return response.data;
    } catch (error) {
        throw error;
    }
};

// Получить список социальных сетей
export const getSocialList = async () => {
    const endpoint_url = `/services/social/list/`
    try {
        const response = await api.get(endpoint_url);
        return response.data;
    } catch (error) {
        throw error;
    }
};

// Получить список категорий накрутки по соц.сети
export const getCategoriesList = async (social) => {
    const endpoint_url = `/services/category/list/`
    const params = {
        "social": social,
    }
    try {
        const response = await api.get(endpoint_url, {params: params});
        return response.data;
    } catch (error) {
        throw error;
    }
};

// Получить список услуг по соц.сети и категории
export const getServicesList = async (social = null, category = null) => {
    const endpoint_url = `/services/list/`
    const params = {}

    if (social !== null) {
        params["social"] = social;
    }

    if (category !== null) {
        params["category"] = category;
    }

    try {
        const response = await api.get(endpoint_url, { params });
        return response.data;
    } catch (error) {
        throw error;
    }
};
