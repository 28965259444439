import React from 'react';
import "./OrderTotal.css";
import { HiOutlineCash, HiOutlineShoppingBag } from "react-icons/hi";

function OrderTotal() {

    return (
        <div>
            <div className="card card--light-gray">
                <div className='summary'>
                    <div>
                        <span className='color-gray'>Количество [Лайки]</span>
                        <div>101</div>
                    </div>
                    <div>
                        <span className='color-gray'>Сумма заказа</span>
                        <div>10₽</div>
                    </div>
                    {/* <div>
                        <span className='primary-color'>Персональная скидка</span>
                        <div className='primary-color bold'>-10%</div>
                    </div> */}
                    <div>
                        <span className='primary-color'>Скидка за количество</span>
                        <div className='primary-color bold'>-15%</div>
                    </div>
                </div>
            </div>
            <div className="card">
                <div className='total-price'>
                    Итого:
                    <div className='bold'>145₽</div>
                </div>
            </div>
            {/* <div className="card card--light-gray">
                <div className='color-red'>
                    Для оформления не хватает 12₽
                </div>
                <button className="btn btn--small btn--dark-gray"><HiOutlineCash size={20} /> Пополнить баланс на 14₽</button>
            </div> */}
            {/* <button className="btn">
                <HiOutlineShoppingBag size={20} />
                Оформить заказ
            </button>
            <button className="btn" disabled>
                <HiOutlineShoppingBag size={20} />
                Оформить заказ
            </button> */}
        </div>
    );
}

export default OrderTotal;