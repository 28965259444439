import React, { useEffect, useState } from 'react';
import './WebappOrderEdit.css'
import { getOrder, updateOrder, createInvoice, submitOrder } from "../../api/api";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams, useLocation } from "react-router-dom";
import { setOrderData } from "../../redux/actions/orderActions";
import ErrorList from "../../components/InputError/InputError";
import OrderTotal from "../../components/OrderTotal/OrderTotal"
import ServiceDescription from "../../components/ServiceDescription/ServiceDescription.tsx"
import { useTelegram } from "../../hooks/useTelegram";
import { setUserUUID } from "../../redux/actions/userActions"


const WebappOrderEdit = () => {
    const dispatch = useDispatch();
    const { tg } = useTelegram();
    const user = useSelector((state) => state.user);
    const order = useSelector((state) => state.order);
    const [editedOrder, setEditedOrder] = useState(order);
    const [isEditing, setIsEditing] = useState(false);
    const [errors, setErrors] = useState([]);
    const [searchParams] = useSearchParams();
    const location = useLocation();

    useEffect(() => {
        // Обработка get параметров при открытии WebApp из бота.
        const user_uuid = searchParams.get("user_uuid");
        const bot = searchParams.get("bot");
        const service_id = searchParams.get("service_id");
        const order_uuid = searchParams.get("order_uuid");

        // webapp открылся для редактирования конкретного заказа
        if (order_uuid && user_uuid && !(service_id && bot)) {
            dispatch(setUserUUID(user_uuid));
            const params = {
                order_unique_id: order_uuid,
            };
            getOrderInfo(params);
        }

        // webapp открылся для создания заказа по услуге
        if (user_uuid && bot && service_id) {
            dispatch(setUserUUID(user_uuid));
            const params = {
                service_id: service_id,
                user_uuid: user_uuid,
                bot: bot,
            };
            getOrderInfo(params);
        }
        tg.ready();
        window.ym(94916614, 'hit', location.pathname);
    }, [])

    const getOrderInfo = async (params) => {
        try {
            const response = await getOrder(params);
            dispatch(setOrderData(response));
        } catch (error) {
            console.error('Ошибка при получении заказа', error);
        }
    }

    useEffect(() => {
        setEditedOrder(order);
    }, [order]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditedOrder({ ...editedOrder, [name]: value });
        setIsEditing(true);
    };

    const onUpdateOrder = async () => {
        const dataToUpdate = {};

        if (editedOrder.count !== null) {
            dataToUpdate.count = editedOrder.count;
        }

        if (editedOrder.link !== null) {
            dataToUpdate.link = editedOrder.link;
        }
        try {
            const response = await updateOrder(order.unique_id, dataToUpdate);
            dispatch(setOrderData(response));
            setErrors([]);
        } catch (error) {
            if (error.response.data !== null) {
                setErrors(error.response.data);
            } else {
                console.error('Ошибка при обновлении заказа', error);
            }
        }
    }

    useEffect(() => {
        if (isEditing) {
            const delayDebounceFn = setTimeout(() => {
                onUpdateOrder();
                setIsEditing(false);
            }, 800)
            return () => clearTimeout(delayDebounceFn)
        }
    }, [editedOrder]);

    const is_can_place_order = order.count && order.link;
    const is_money_enough = user.balance >= order.total_price;
    let needed_money = 0;
    if (!is_money_enough) {
        needed_money = (order.total_price - user.balance).toFixed(2);
    };

    const onPayButtonClick = async () => {
        try {
            await createInvoice(order.unique_id);
            tg.close();
        } catch (error) {
            console.error('Ошибка при создании инвойса', error);
        }
    }

    const onSubmitButtonClick = async () => {
        try {
            await submitOrder(order.unique_id);
            tg.close();
        } catch (error) {
            console.error('Ошибка при подтверждении заказа', error);
        }
    }

    return (
        <div className={'form'}>
            <ServiceDescription service={order.service} />
            <h4>Новый заказ</h4>
            <div className="form-row">
                <label htmlFor="link">Ссылка:</label>
                <input
                    type="search"
                    name='link'
                    className={'input'}
                    value={editedOrder.link}
                    onChange={handleInputChange}
                    placeholder={'Куда выполнить накрутку'}
                />
                <ErrorList name={'link'} errors={errors} />
            </div>
            <div className="form-row">
                <label htmlFor="count">Количество {order.service.service_type_plural}:</label>
                <input
                    type="number"
                    name='count'
                    className={'input'}
                    value={editedOrder.count}
                    onChange={handleInputChange}
                    placeholder={'Количество'}
                />
                <ErrorList name={'count'} errors={errors} />
            </div>
            <OrderTotal order={order} is_money_enough={is_money_enough} needed_money={needed_money} />
            

        </div>
    );
};

export default WebappOrderEdit;