import './App.css';
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer"
import { Route, Routes } from "react-router-dom";
import WebappOrderEdit from "./pages/WebappOrderEdit/WebappOrderEdit";
import Homepage from "./pages/Homepage/Homepage";
import OrderCreate from "./pages/OrderCreate/OrderCreate";
import ServicesTable from "./pages/ServicesTable/ServicesTable";
import Balance from "./pages/Balance/Balance";
import UserAgreement from "./pages/UserAgreement/UserAgreement";
import Privacy from "./pages/Privacy/Privacy";
import PaymentsSafety from "./pages/PaymentsSafety/PaymentsSafety";

function App() {

    return (
        <div className='content'>
            <Header />
            <div className="content-width fg-1">
                <Routes>
                    <Route index element={<Homepage />} />
                    <Route path='webapp/order/' element={<WebappOrderEdit />} />
                    <Route path='order/create/' element={<OrderCreate />} />
                    <Route path='services/list/' element={<ServicesTable />} />
                    <Route path='rules/' element={<UserAgreement />} />
                    <Route path='privacy/' element={<Privacy />} />
                    <Route path='payments-safety/' element={<PaymentsSafety />} />
                    <Route path='balance/' element={<Balance />} />
                </Routes>
            </div>
            <Footer />
        </div>
    );
}

export default App;
