import "./LoginOffer.css";
import { LiaTelegramPlane } from "react-icons/lia";

function LoginOffer() {

    return (
        <div className='card card--black login-offer'>
            <h2>Авторизуйтесь для оформления заказа</h2>
            <p>Всем новым пользователям дарим бесплатную накрутку для теста</p>
            <button className='btn'>
                <LiaTelegramPlane size={20} />
                Войти через Telegram
            </button>
        </div>
    );
}

export default LoginOffer;