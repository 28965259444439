const initialState = {
    unique_id: null,
    service: {
        title: null,
        description: null,
        min_value: null,
        max_value: null,
        price: null,
        service_social: null,
        tags: [],
        is_price_per_one: false,
    },
    count: null,
    link: null,
    total_price: null,
    raw_total_price: null,
    discount_dynamic_percent: null,
    discount_personal_percent: null,
    offer: null,
    summary: [],
};

const orderReducer = (state = initialState, action) => {
    const data = action.payload;
    switch (action.type) {
        case 'SET_ORDER_DATA':
            return {
                ...state,
                unique_id: data.order.unique_id,
                count: data.order.count,
                link: data.order.link,
                total_price: data.order.total_price,
                raw_total_price: data.order.raw_total_price,
                discount_dynamic_percent: data.order.discount_dynamic_percent,
                discount_personal_percent: data.order.discount_personal_percent,
                offer: data.order.offer,
                service: data.order.service,
                summary: data.order.summary,
            };
        default:
            return state;
    }
};

export default orderReducer;